/* Coolors Exported Palette - coolors.co/479989-d35901-5e8abb-f1bc33-e1ce9a */

/* HSL */
$color1: hsla(168%, 37%, 44%, 1);
$color2: hsla(25%, 99%, 42%, 1);
$color3: hsla(212%, 41%, 55%, 1);
$color4: hsla(43%, 87%, 57%, 1);
$color5: hsla(44%, 54%, 74%, 1);

/* RGB */
$color1: rgba(71, 153, 137, 1);
$color2: rgba(211, 89, 1, 1);
$color3: rgba(94, 138, 187, 1);
$color4: rgba(241, 188, 51, 1);
$color5: rgba(225, 206, 154, 1);
$color6: rgba(0,0,0,1);