/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 7, 2017 */
@import url(/assets/fonts/casual/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_brush_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_display_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_displayline_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_extrovert_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_introvert_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_poster_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_serif_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_shadowdisplay_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_shadowdisplayline_macroman/stylesheet.css);
/* Coolors Exported Palette - coolors.co/479989-d35901-5e8abb-f1bc33-e1ce9a */
/* HSL */
/* RGB */
@import url(/assets/fonts/casual/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_brush_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_display_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_displayline_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_extrovert_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_introvert_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_poster_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_serif_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_shadowdisplay_macroman/stylesheet.css);
@import url(/assets/fonts/tomarik/Webfonts/tomarik_shadowdisplayline_macroman/stylesheet.css);
button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none;
  background-color: #1565c0;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Goudy Bookletter 1911", serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, [type='button']:hover, [type='button']:focus, [type='reset']:hover, [type='reset']:focus, [type='submit']:hover, [type='submit']:focus {
    background-color: #11519a;
    color: #fff; }
  button:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover {
      background-color: #1565c0; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0; }

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em; }

input,
select,
textarea {
  display: block;
  font-family: "Goudy Bookletter 1911", serif;
  font-size: 1em; }

[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  [type='color']:hover, [type='date']:hover, [type='datetime']:hover, [type='datetime-local']:hover, [type='email']:hover, [type='month']:hover, [type='number']:hover, [type='password']:hover, [type='search']:hover, [type='tel']:hover, [type='text']:hover, [type='time']:hover, [type='url']:hover, [type='week']:hover, input:not([type]):hover, textarea:hover {
    border-color: #b1b1b1; }
  [type='color']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='email']:focus, [type='month']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='time']:focus, [type='url']:focus, [type='week']:focus, input:not([type]):focus, textarea:focus {
    border-color: #1565c0;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(18, 89, 169, 0.7);
    outline: none; }
  [type='color']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='email']:disabled, [type='month']:disabled, [type='number']:disabled, [type='password']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='text']:disabled, [type='time']:disabled, [type='url']:disabled, [type='week']:disabled, input:not([type]):disabled, textarea:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    [type='color']:disabled:hover, [type='date']:disabled:hover, [type='datetime']:disabled:hover, [type='datetime-local']:disabled:hover, [type='email']:disabled:hover, [type='month']:disabled:hover, [type='number']:disabled:hover, [type='password']:disabled:hover, [type='search']:disabled:hover, [type='tel']:disabled:hover, [type='text']:disabled:hover, [type='time']:disabled:hover, [type='url']:disabled:hover, [type='week']:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
      border: 1px solid #ddd; }
  [type='color']::placeholder, [type='date']::placeholder, [type='datetime']::placeholder, [type='datetime-local']::placeholder, [type='email']::placeholder, [type='month']::placeholder, [type='number']::placeholder, [type='password']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='text']::placeholder, [type='time']::placeholder, [type='url']::placeholder, [type='week']::placeholder, input:not([type])::placeholder, textarea::placeholder {
    color: #666666; }

textarea {
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  margin-bottom: 0.75em;
  width: 100%; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

html,
body {
  height: 100%; }

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

dl {
  margin: 0; }

dt {
  font-weight: 600;
  margin: 0; }

dd {
  margin: 0; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%; }

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

tr,
td,
th {
  vertical-align: middle; }

body {
  color: black;
  font-family: "Goudy Bookletter 1911", serif;
  font-size: 1em;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "tomarik-serif", serif;
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0 0 0.75em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #1565c0;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #104c90; }

hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

/* Coolors Exported Palette - coolors.co/479989-d35901-5e8abb-f1bc33-e1ce9a */
/* HSL */
/* RGB */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 7, 2017 */
body,
html {
  width: 100%;
  height: 100%; }

body {
  font-family: "Goudy Bookletter 1911", serif; }

.btn-xl {
  padding: 1.25rem 2.5rem; }

.content-section {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem; }

.content-section-heading h2 {
  font-size: 3rem; }

.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

.text-faded {
  color: rgba(255, 255, 255, 0.7); }

/* Map */
.map {
  height: 30rem; }

@media (max-width: 992px) {
  .map {
    height: 75%; } }

.map iframe {
  pointer-events: none; }

.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  background: rgba(52, 58, 64, 0.5);
  line-height: 45px; }
  .scroll-to-top:focus, .scroll-to-top:hover {
    color: white; }
  .scroll-to-top:hover {
    background: #343a40; }
  .scroll-to-top i {
    font-weight: 800; }

.masthead {
  min-height: 30rem;
  position: relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: url("../img/bg-masthead.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e1ce9a; }
  .masthead h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0; }
  @media (min-width: 992px) {
    .masthead {
      height: 100vh; }
      .masthead h1 {
        font-size: 5.5rem; } }
  .masthead h3 {
    font-family: "Goudy Bookletter 1911", serif;
    font-size: 2em; }
    .masthead h3 strong {
      font-weight: 900; }
    .masthead h3 em {
      font-style: normal;
      font-weight: 100; }

/* Side Menu */
#sidebar-wrapper {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  transform: translateX(250px);
  background: #5e8abb;
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none; }

.sidebar-nav li.sidebar-nav-item a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 15px; }

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2); }

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none; }

.sidebar-nav > .sidebar-brand {
  font-size: 1.2rem;
  background: rgba(52, 58, 64, 0.1);
  height: 80px;
  line-height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px; }

.sidebar-nav > .sidebar-brand a {
  color: #fff; }

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none; }

#sidebar-wrapper.active {
  right: 250px;
  width: 250px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

.menu-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff;
  background: rgba(52, 58, 64, 0.5);
  line-height: 50px;
  z-index: 999; }
  .menu-toggle:focus, .menu-toggle:hover {
    color: #fff; }
  .menu-toggle:hover {
    background: #343a40; }

.service-icon {
  background-color: #fff;
  color: #5e8abb;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); }

.callout {
  padding: 15rem 0;
  background: url("../img/bg-callout.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e1ce9a; }
  .callout h2 {
    font-size: 3.5rem;
    font-weight: 700;
    display: block;
    max-width: 30rem; }

.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem; }
  .portfolio-item .caption {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(33, 37, 41, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1; }
    .portfolio-item .caption .caption-content {
      color: #fff;
      margin: auto 2rem 2rem; }
      .portfolio-item .caption .caption-content h2 {
        font-size: 0.8rem;
        text-transform: uppercase; }
      .portfolio-item .caption .caption-content p {
        font-weight: 300;
        font-size: 1.2rem; }
  @media (min-width: 992px) {
    .portfolio-item {
      max-width: none;
      margin: 0; }
      .portfolio-item .caption {
        -webkit-transition: -webkit-clip-path 0.25s ease-out, background-color 0.7s;
        -webkit-clip-path: inset(0px);
        clip-path: inset(0px); }
        .portfolio-item .caption .caption-content {
          transition: opacity 0.25s;
          margin-left: 5rem;
          margin-right: 5rem;
          margin-bottom: 5rem; }
      .portfolio-item img {
        -webkit-transition: -webkit-clip-path 0.25s ease-out;
        -webkit-clip-path: inset(-1px);
        clip-path: inset(-1px); }
      .portfolio-item:hover img {
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem); }
      .portfolio-item:hover .caption {
        background-color: rgba(94, 138, 187, 0.9);
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem); } }

footer.footer {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  footer.footer .social-link {
    display: block;
    height: 4rem;
    width: 4rem;
    line-height: 4.3rem;
    font-size: 1.5rem;
    background-color: #5e8abb;
    transition: background-color 0.15s ease-in-out;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); }
    footer.footer .social-link:hover {
      background-color: #4470a2;
      text-decoration: none; }

a {
  color: #5e8abb; }
  a:hover, a:focus, a:active {
    color: #4470a2; }

.btn-primary {
  background-color: #5e8abb !important;
  border-color: #5e8abb !important;
  color: #fff !important; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background-color: #4470a2 !important;
    border-color: #4470a2 !important; }

.btn-secondary {
  background-color: #ecb807 !important;
  border-color: #ecb807 !important;
  color: #fff !important; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background-color: #ba9106 !important;
    border-color: #ba9106 !important; }

.btn-dark {
  color: #fff !important; }

.btn {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  font-weight: 700; }

.bg-primary {
  background-color: #5e8abb !important; }

.text-primary {
  color: #5e8abb !important; }

.text-secondary {
  color: #ecb807 !important; }
