body,
html {
  width: 100%;
  height: 100%;
}

body {
  @include body-font;
}

// Custom Button Size
.btn-xl {
  padding: 1.25rem 2.5rem;
}

// Content Section
.content-section {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.content-section-heading {
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 1rem;
    text-transform: uppercase;
  }
}

// Typography
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.text-faded {
  color: fade-out($white, 0.3);
}

/* Map */

.map {
  height: 30rem;
}

@media(max-width: 992px) {
  .map {
    height: 75%;
  }
}

.map iframe {
  pointer-events: none;
}

// Scroll to top button
.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;

  display: none;

  width: 50px;
  height: 50px;

  text-align: center;

  color: white;
  background: fade-out($gray-800, .5);

  line-height: 45px;
  &:focus,
  &:hover {
    color: white;
  }
  &:hover {
    background: $gray-800;
  }
  i {
    font-weight: 800;
  }
}
