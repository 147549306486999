/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 7, 2017 */

@import '/assets/fonts/casual/stylesheet.css';



@import '/assets/fonts/tomarik/Webfonts/tomarik_brush_macroman/stylesheet.css';
@import '/assets/fonts/tomarik/Webfonts/tomarik_display_macroman/stylesheet.css';
@import '/assets/fonts/tomarik/Webfonts/tomarik_displayline_macroman/stylesheet.css';
@import '/assets/fonts/tomarik/Webfonts/tomarik_extrovert_macroman/stylesheet.css';
@import '/assets/fonts/tomarik/Webfonts/tomarik_introvert_macroman/stylesheet.css';
@import '/assets/fonts/tomarik/Webfonts/tomarik_poster_macroman/stylesheet.css';
@import '/assets/fonts/tomarik/Webfonts/tomarik_serif_macroman/stylesheet.css';
@import '/assets/fonts/tomarik/Webfonts/tomarik_shadowdisplay_macroman/stylesheet.css';
@import '/assets/fonts/tomarik/Webfonts/tomarik_shadowdisplayline_macroman/stylesheet.css';








$casual: 'casualregular', serif;
$goudy: 'Goudy Bookletter 1911', serif;





$tbrush:'tomarik-brush', serif;
$tdis:'tomarik-display', serif;
$tdisline:'tomarik-displayline', serif;
$textrovert:'tomarik-extrovert', serif;
$tintrovert:'tomarik-introvert', serif;
$tposter:'tomarik-poster', serif;
$tserif: 'tomarik-serif', serif;
$tshadow:'tomarik-displayshadow', serif;
$tshadowinline:'tomarik-displaylineshadow', serif;