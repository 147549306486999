// Callout
.callout {
  padding: 15rem 0;
  background:url('../img/bg-callout.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color5;

  h2 {
    font-size: 3.5rem;
    font-weight: 700;
    display: block;
    max-width: 30rem;
  }
}
