.masthead {
  min-height: 30rem;
  position: relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: url('../img/bg-masthead.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color5;
  h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0;
  }
  @media (min-width: 992px) {
    height: 100vh;
    h1 {
      font-size: 5.5rem;
    }
  }
  h3{
    font-family:$goudy;
    font-size:2em;
    strong{
      font-weight: 900;

    }
    em{
      font-style: normal;
      font-weight: 100;
    }
  }
}
