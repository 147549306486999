footer.footer {
  padding-top: 5rem;
  padding-bottom: 5rem;
  .social-link {
    display: block;
    height: 4rem;
    width: 4rem;
    line-height: 4.3rem;
    font-size: 1.5rem;
    background-color: $primary;
    transition: background-color 0.15s ease-in-out;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
    &:hover {
      background-color: darken($primary, 10%);
      text-decoration: none;
    }
  }
}
